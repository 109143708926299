import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import { ReactComponent as IconTikTok } from './assets/icons/tiktok.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";
import { ReactComponent as IconLogo } from './assets/icons/logo.svg';

class App extends React.Component {
  render = () => {
    return (
  <div className="cardback">
  <div className="card">
        <div className="header">
          <div className="sociala">
            <a href="https://www.west.bz" title="Twitter" target="_blank" rel="noopener noreferrer">
              {/* <IconLogo className="icon" /> */}
            </a>
</div>
          <div className="social">
            <a href="https://twitter.com/averyjwest" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://instagram.com/realaverywest" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
            <a href="https://tiktok.com/@realaverywest" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTikTok className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">

            <h1>Welcome to Team West Employee Ticketing Service.</h1>
            <p>We provide all tickets provided by our partners here.</p>
          </div>
          <a href="https://tickets.west.bz/wallet/JVs5fkCYaKNdzRF7d2ZMoa/">
            <div className="cta">Find your ticket here</div>
          </a>
        </div>
      </div>
        <div className="footer">
          <span>Learn about <a className="underlined" href="https://www.west.bz/about/company" target="_blank" rel="noopener noreferrer">Team West here</a>.</span>
        </div>
   </div>
    );
  }
}

export default App;